<template>
  <b-card class="m-0 p-0">
    <b-card-body class="m-0 p-0">
      <b-overlay :show="isButtonClick">
        <b-table
            size="sm"
            striped
            hover
            responsive
            :items="_invoiceList"
            :fields="_fields"
            :tbody-tr-class="rowClass"
            class="p-0 m-0"
        >
          <template #cell(invoice_detail)="data">
            <b-badge>{{ data.item.invoice_id }}</b-badge>
            <br>
            <view-date :date="data.item.create_date_utc"/>
          </template>

          <template #cell(customer_detail)="data">
            <b-badge variant="">{{ data.item.target_tckn_vkn }}</b-badge>
            <br>
            <span variant="">{{ data.item.target_title }}</span>
          </template>

          <template #cell(archived)="data">
            <b-badge :variant="data.item.is_archived?'success':'info'">Arşiv</b-badge>
            <br>
            <span>Seneryo: {{ data.item.scenario }}</span>
          </template>

          <template #cell(invoice_type)="data">
            <b-badge>{{ getInvoiceTipType(data.item.invoice_tip_type).name }}</b-badge>
            <br>
            <b-badge>{{ getInvoiceType(data.item.type).name }}</b-badge>
          </template>

          <template #cell(status_detail)="data">
            <b-badge
                v-if="data.item.status"
                :variant="getStatus(data.item.status).variant">
              {{ getStatus(data.item.status).value }}
            </b-badge>
            <br>
            <b-badge
                v-if="data.item.envelope_status"
                :variant="getStatus(data.item.envelope_status).variant">
              {{ getStatus(data.item.envelope_status).value }}
            </b-badge>
          </template>

          <template #cell(envelope_status)="data">

          </template>

          <template #cell(actions)="data">

            <b-button-group size="sm">
              <b-button
                  variant="success"
                  v-if="data.item.in_out==1"
                  :disabled="data.item.type!=1"
                  @click="successInvoice(data.item,2)"
              >
                <feather-icon icon="CheckSquareIcon"/>
              </b-button>
              <b-button
                  variant="danger"
                  v-if="data.item.in_out==1"
                  :disabled="data.item.type!=1"
                  @click="successInvoice(data.item,1)"
              >
                <feather-icon icon="XCircleIcon"/>
              </b-button>
              <b-button variant="info" @click="showInvoice(data.item)">
                <feather-icon icon="FileTextIcon"/>
              </b-button>
              <b-button variant="info" @click="changeIsNew(data.item)">
                <feather-icon :icon="data.item.is_new?'EyeIcon':'EyeOffIcon'"/>
              </b-button>

            </b-button-group>

          </template>

        </b-table>
      </b-overlay>
    </b-card-body>
    <b-card-footer>
      <Pagination :pagination="_pagination" @pageClick="getInvoiceList"/>
    </b-card-footer>
  </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from '@/components/Pagination'
import viewDate from "@/views/common/ViewDate.vue";
import axios from "@/libs/axios";

export default {
  name: "InboxHome",
  components: {Pagination, viewDate},
  computed: {
    ...mapGetters("invoice", ["_invoiceList", "_pagination", "_searchData", "_fields", "_status", "_invoice_type", "_invoice_tip_type"])
  },
  data: () => ({
    isButtonClick: false,
  }),
  methods: {
    getInvoiceList(page) {
      this._searchData.page = page
      this.$store.dispatch('invoice/getInvoiceList')
    },
    getInvoiceType(type) {
      let status = this._invoice_type.find(item => item.value == type)
      if (status) return status;
      return {}
    },
    getInvoiceTipType(type) {
      let status = this._invoice_tip_type.find(item => item.value == type)
      if (status) return status;
      return {}
    },
    getStatus(statu) {
      let status = this._status.find(item => item.statu == statu);
      if (status) return status;
      return {}
    },
    showInvoice(item) {
      this.isButtonClick = true;
      let action = item.in_out == 1 ? 'GetInboxInvoicePdf' : 'GetOutboxInvoicePdf'
      this.$store.dispatch('invoice/showInvoice', {documentId: item.document_id, action})
          .then(res => {
            if (res.status == 'success') {
              this.$swal({
                width: '800px',
                html: '<embed src="data:application/pdf;base64,' + res.content + '" type="application/pdf" width="700" height="700" />'
              })
              item.is_new = 0;
            } else {
              this.$swal({
                width: '800px',
                html: 'Çekeme Hatası'
              })
            }
            this.isButtonClick = false;
          })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.is_new) return 'table-success';

    },
    successInvoice(item, status) {

      let text = status == 2 ? "Onaylamak istediğinizden emin misiniz?" : "Red etmek istediğinizden emin misiniz?";
      if (confirm(text) == true) {
        axios.post('invoice/sendresponse/' + item.company_id + '/' + item.document_id, {
          aciton: 'SendDocumentResponse',
          parameters: {
            InvoiceId: item.document_id,
            Status: status
          }
        })
      }
    },
    changeIsNew(item) {
      let is_new = item.is_new == 1 ? 0 : 1;
      this.$store.dispatch('invoice/updateInvoice', {document_id: item.document_id, is_new})
          .then(res => {
            if (res.status == 'success')
              item.is_new = is_new;
          })
    }
  },
  mounted() {
    this.$store.dispatch('invoice/getInvoiceList');
  }
}
</script>

<style scoped>

</style>
