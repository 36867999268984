<template>
  <b-row v-if="pagination">
    <b-col md="6">
      <b-badge variant="info">{{ pagination.from }} - {{ pagination.to }}</b-badge>
      <b-badge variant="warning">
        {{ pagination.per_page < pagination.total ? pagination.per_page : pagination.total }} /
        {{ pagination.total }}
      </b-badge>
      <b-badge variant="success">Sayfa: {{ pagination.current_page }} / {{ pagination.last_page }}</b-badge>
    </b-col>
    <b-col md="6">
      <b-pagination
          v-model="pagination.current_page"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          limit="9"
          align="right"
          first-number
          last-number
          class="pagination-success"
          size="sm"
          @page-click="pageClick"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: false
    }
  },
  methods: {
    pageClick(bvEvent, page) {
      bvEvent.preventDefault()
      this.$emit('pageClick', page)
    }
  }
}
</script>

<style scoped>

</style>
